var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"ma-1 pa-1"},[_c('v-card-title',{staticClass:"pa-1"},[_c('span',[_vm._v(_vm._s(_vm.$t("reports.index")))]),_c('v-spacer'),(_vm.$store.getters.checkPermission('report_template-create'))?_c('v-btn',{attrs:{"color":"#6ac82d","x-small":"","dark":"","fab":"","to":"template/create"}},[_c('v-icon',[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('v-data-table',{staticClass:"ma-1",staticStyle:{"border":"1px solid #aaa"},attrs:{"dense":"","fixed-header":"","loading-text":_vm.$t('loadingText'),"no-data-text":_vm.$t('noDataText'),"height":_vm.screenHeight,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"item-key":"id","footer-props":{
        itemsPerPageOptions: [50, 100, 200],
        itemsPerPageAllText: _vm.$t('itemsPerPageAllText'),
        itemsPerPageText: _vm.$t('itemsPerPageText'),
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right',
      }},on:{"update:page":_vm.updatePage,"update:items-per-page":_vm.updatePerPage},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_vm._v(_vm._s(_vm.items .map(function (x) { return x.id; }) .indexOf(item.id) + 1))]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [(_vm.$store.getters.checkPermission('report_'+item.permission) || _vm.$store.getters.checkPermission('report_template-update'))?_c('v-btn',{attrs:{"color":"success","small":"","icon":"","to":'unv-report/' + item.id}},[_c('v-icon',[_vm._v("mdi-eye")])],1):_vm._e(),(_vm.$store.getters.checkPermission('report_template-update'))?_c('v-btn',{attrs:{"color":"blue","small":"","icon":"","to":'template/update/' + item.id}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.$store.getters.checkPermission('report_template-delete'))?_c('v-btn',{attrs:{"color":"red","small":"","icon":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}])})],1),_c('v-dialog',{attrs:{"width":"300","hide-overlay":""},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("loadingText"))+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }